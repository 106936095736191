.modal {
    display: table;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -1.5rem;
    margin-top: -0.5rem;
    width: 3rem;
    height: 1rem;
    background: rgba(0, 0, 0, 0.7);
    border-radius: .08rem;
    &-text {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        font-size: .16rem;
        color: #FFF;
        padding: .2rem;
    }
}