.account-page {
    .tab {
        width: 1.5rem;
        line-height: .21rem;
        margin: 0 auto;
        padding: .5rem 0 .77rem 0;
        font-size: .18rem;
        color: #242424;
        font-weight: bold;
    }
    .tab-item {
        display: inline-block;
        &-active {
            a {
                color: #73D13D !important;
            }                     
        }
        &-right {
            float: right; 
        }
        a {
            color: #242424;
            text-decoration: none;
        }
    }
    .form {
        margin-bottom: .6rem;
    }
    .form-item {
        display: flex;
        padding: .15rem .3rem;
        line-height: .24rem;
        font-size: .16rem;
        &-title {
            width: .48rem;
            margin-right: .42rem;
        }
        &-content {
            flex: 1;
            color: #242424;
            border: none;
            outline: none;
            &::placeholder {
                color: #D9D9D9;
            }
        }        
    }
    .submit {
        margin: 0 .3rem .94rem .3rem;
        line-height: .5rem;
        border-radius: .25rem;
        background: #73D13D;
        box-shadow: 0 .06rem .06rem 0 #D9F7BE;
        font-size: .16rem;
        color: #FFF;
        text-align: center;
    }
    .notice {
        color: #CF1322;
        font-weight: regular;
        font-size: .16rem;
        line-height: .24rem;
        letter-spacing: 0;
        text-align: left;
        width: 2.72rem;
        margin: 0 auto;
    }
}


