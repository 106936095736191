.detail-page {
    overflow-y: scroll;             // 使得 page 自动延展为内容长度
    background: #F5F9FA;
    .title {
      position: relative;
      line-height: .44rem;
      font-size: .18rem;
      color: #242424;
      background: #FFF;
      text-align: center;
      .iconfont {
        position: absolute;
        left: .1rem;
        top: 0;
      }
    }
    .image {
      display: block;
      width: 100vw;
      height: 100vw;
    }
    .main {
      margin: .17rem .1rem 0 .1rem;
      position: relative;
      padding-top: .74rem;
      font-size: .18rem;
      background: #52C41A;
      border-radius: .15rem;
      &-price {
        position: absolute;
        color: #FFF;
        font-size: .22rem;
        top: .18rem;
        left: .1rem;
        font-weight: bold;
        &-yen {
          font-size: .14rem;
          margin-right: .02rem;
          font-weight: normal;
        }
      }
      &-sales {
        position: absolute;
        color: #FFF;
        font-size: .16rem;
        right: .17rem;
        top: .18rem;
        font-weight: bold;
      } 
      &-content {
        padding: .2rem .1rem .1rem .1rem;
        margin-top: -.15rem;
        background: #FFF;
        border-radius: .15rem;
        &-title {
          color: #242424;
          font-size: .18rem;
          line-height: .24rem;
        }
        &-subtitle {
          margin-top: .04rem;
          color: #C9CDD4;
          font-size: .14rem;
          line-height: .2rem;
        }
      }
    }
    .spec {
      margin: .1rem .08rem 0 .08rem;
      border-radius: 15px;
      background: #FFF;
      &-title {
        margin-left: .1rem;
        color: #242424;
        font-size: .16rem;
        line-height: .4rem;
      }
      &-content {
        margin: 0 .12rem .12rem .12rem;
        padding-bottom: .12rem;
        display: flex;
        &-left {
          padding: .05rem 0;
          width: .62rem;
          background: #F2F3F5;
          text-align: center;
          border: 1px solid #D7D7D7;
          border-radius:  .05rem 0 0 .05rem;
        }
        &-right {
          padding: .05rem .1rem;
          flex: 1;
          border-top: 1px solid #D7D7D7;
          border-right: 1px solid #D7D7D7;
          border-bottom: 1px solid #D7D7D7;
          border-radius:  0 .05rem .05rem 0;
        }
        &-item {
          color: #242424;
          font-size: .14rem;
          line-height: .2rem;
        }
      }
    }
    .detail {
      margin: .1rem .08rem 0 .08rem;
      border-radius: 15px;
      background: #FFF;
      &-title {
        margin-left: .1rem;
        color: #242424;
        font-size: .16rem;
        line-height: .4rem;
      }
      &-content {
        padding: .13rem .16rem .16rem .16rem;
        color: #242424;
        font-size: .16rem;
        line-height: .27rem;
      }
    }
    .docker-area {
      height: .78rem;
    }
    .docker {
      display: flex;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      height: .64rem;
      background: #FFF;
      border-top: .01rem solid #F5F9FA;
      .cart-icon {
        position: relative;
        width: 1.2rem;
        text-align: center;
        .iconfont {
          margin-top: .1rem;
          font-size: .24rem;
        }
        &-count {
          position: absolute;
          right: .4rem;
          top: .07rem;
          width: .16rem;
          height: .16rem;
          font-size: .11rem;
          line-height: .16rem;
          text-align: center;
          background: #73D13D;
          color: #FFF;
          border-radius: 50%;
        }
        .icon-text {
          font-size: .14rem;
        }
      }
      .cart-button {
        flex: 1;
        margin-top: .1rem;
        margin-right: .16rem;
        height: .44rem;
        line-height: .44rem;
        font-size: .16rem;
        background: #73D13D;
        border-radius: .22rem;
        text-align: center;
        color: #FFF;
      }
    }
  }