.profile-page {
    //background: #F5F9FA;
    background: linear-gradient(180deg, #52C41A 1%, #74D13F 32%, #F5F9FA 51%);
    box-sizing: border-box;
    padding-left: .1rem;
    padding-right: .1rem;
    
    /* .background-color {
        height: 3.37rem;
        background: linear-gradient(180deg, #52C41A 1%, #74D13F 64%, #FEFEFE 100%);
    } */
    .title {
        line-height: .44rem;
        color: #FFF;
        font-size: .16rem;
        text-align: center;
    }
    .user-info {
        position: relative;
        height: .92rem;
        background: transparent;
        overflow: hidden;
        &-img {
            display: block;
            width: .64rem;
            height: .64rem;
            margin-top: .08rem;
            float: left;
        }
        &-name {
            margin-top: .27rem;
            margin-left: .78rem;
            color: #FFF;
            font-size: .18rem;
        }
        .log-out {
            position: absolute;
            top: .27rem;
            right: 0;
            color: #FFF;
            display: flex;
            align-items: center;
            .text {
                font-size: .12rem;
                margin-right: .05rem;
            }
            .iconfont {
                font-size: .18rem;
            }
        }
    }
    .discount {
        width: 100%;
        background: #FFF;
        height: .55rem;
        border-radius: .08rem;
        &-item {
            color: #000;
            height: .55rem;
            width: .5rem;
            float: left;
            margin-top: .08rem;
            margin-left: .3rem;
            &-count {
                font-size: .18rem;
                line-height: .21rem;
                text-align: center;
            }
            &-name {
                font-size: .12rem;
                line-height: .18rem;
                text-align: center;
            }
        }
    }
    .account-info {
        display: flex;
        flex-wrap: wrap;
        height: 1.58rem;
        width: 100%;
        background: #FFF;
        border-radius: .08rem;
        &-item {
            flex-basis: 25%;
            text-align: center;
            margin-top: .1rem;
            margin-bottom: .1rem;
            height: .58rem;
            &-img {
                display: block;
                width: .4rem;
                height: .4rem;
                margin-left: auto;
                margin-right: auto;
            }
            &-desc {
                color: #242424;
                font-size: .12rem;
                line-height: .18rem;
                text-align: center;
            }
        }
    }

    .note {
        box-sizing: border-box;
        // padding-left: .05rem;
        padding-top: .4rem;
        text-align: center;
        color: #B1B1B1;
        font-size: .12rem;
        
    }

}