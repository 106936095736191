.search-list-page {
    display: flex;
    flex-direction: column;
    height: 100vh;

    .header {
        position: fixed;
        top: 0;
    }

    .back {
        position: absolute;
        left: .15rem;
        top: .14rem;
        z-index: 1;
        &-icon {
            font-size: .14rem;
            color: #242424;
        }
    }

    .search {
        position: relative;
        padding-top: .06rem;
        padding-bottom: .06rem;
        margin: 0 .1rem;
        font-size: .14rem;
        color: #242424;

        &-icon {
            position: absolute;
            left: .42rem;
            top: .14rem;
            color: #75818F;
        }

        &-input {
            margin: 0 auto;
            width: 2.96rem;
            padding-left: .34rem;
            padding-right: .15rem;
            box-sizing: border-box;
            outline: none;
            border: none;
            display: block;
            line-height: .34rem;
            height: .34rem;
            background: #F5F9FA;
            border-radius: .17rem;
            color: #242424;
        }

        .close {
            font-size: .15rem;
            position: absolute;
            top: .14rem;
            right: .42rem;
        }
    }

    .order-by {
        overflow: hidden;
        &-item {
            width: 1.25rem;
            height: .4rem;
            color: #B1B1B1;
            font-size: .14rem;
            line-height: .4rem;
            text-align: center;
            float: left;
            &-active {
                color: #242424 !important;
            }
        }
    }

    .list {
        background: #F5F9FA;
        box-sizing: border-box;
        padding: .1rem;
        flex-grow: 1; /* Allow .auto-fill to expand and fill the available space */
        margin-top: .86rem; /* Push content down so it starts below the fixed header.  */
        overflow-y: auto;
        &-item {
            margin-bottom: .1rem;
            width: 100%;
            background: #FFF;
            border-radius: .08rem;
            overflow: hidden;
            &-pic {
                display: block;
                width: 1.08rem;
                height: 1.08rem;
                margin: .1rem;
                float: left;
            }
            &-desc {
                position: relative;
                margin: .1rem .1rem 0 1.28rem;
                color: #242424;
                font-size: .14rem;
                line-height: .21rem;
                height: 1.18rem;
                .sold {
                    position: absolute;
                    right: 0;
                    bottom: .1rem;
                    color: #B1B1B1;
                    font-size: .11rem;
                    line-height: .16rem;
                }
            }
            &-price {
                position: absolute;
                left: 0;
                bottom: .1rem;
                color: #F81C00;
            }
        }
    }
    .bottom {
        background: #F5F9FA;
        line-height: .63rem;
        font-size: .12rem;
        color: #B3C1C4;
        text-align: center;
        padding-bottom: .3rem;
    }

    
}