.payment-success-page {
    // background: #F5F9FA;
    background: #FFF;

    .container {
        background: #FFF;
        height: 4.85rem;
        overflow: hidden;
        img {
            display: block;
            width: 1.1rem;
            height: 1.1rem;
            margin-top: .9rem;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: .15rem;
        }
        .success-text {
            color: #52C41A;
            font-size: .2rem;
            line-height: .3rem;
            text-align: center;
            margin-bottom: .3rem;
        }
        .button {
            width: 2.33rem;
            line-height: .5rem;
            background: #73D13D;
            box-shadow: 0 .03rem .06rem 0 #95DE64;
            border-radius: .2rem;
            color: #FFF;
            font-size: .16rem;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
        }
    }
}