.address-page {
    overflow-y: scroll;
    background: #F5F9FA;

    .title {
        position: relative;
        line-height: .44rem;
        font-size: .18rem;
        color: #242424;
        background: #FFF;
        text-align: center;

        .iconfont {
            position: absolute;
            left: .1rem;
            top: 0;
        }
    }

    .address-list {
        .address-item {
            height: 1.28rem;
            background: #FFF;
            flex: 1;
            overflow: hidden;
            padding-left: .16rem;
            margin-top: .08rem;

            .contact {
                color: #242424;
                font-size: .14rem;
                line-height: .21rem;
                margin-top: .15rem;
                display: flex;

                &-phone {
                    margin-left: .15rem;
                    margin-right: auto;
                }
            }

            .address {
                margin-top: .07rem;
                color: #242424;
                font-size: .12rem;
                line-height: .18rem;
            }

            .bottom-container {
                margin-top: .3rem;
                display: flex;
                align-items: center;
                .default-icon {
                    background-color: #B7EB8F;
                    color: #237804;
                    border-radius: .02rem;
                    font-size: .09rem;
                    line-height: .13rem;
                    text-align: center;
                    padding: 0 .05rem;
                    margin-right: auto;
                }
                .edit-button {
                    color: #242424;
                    font-size: .14rem;
                    line-height: .21rem;
                    border-radius: .05rem;
                    text-align: center;
                    border: .01rem solid #D0D9DB;
                    padding: 0 .05rem;
                    margin-left: auto;
                    margin-right: .08rem;
                }
                .delete-button {
                    color: #242424;
                    font-size: .14rem;
                    line-height: .21rem;
                    border-radius: .05rem;
                    text-align: center;
                    border: .01rem solid #D0D9DB;
                    padding: 0 .05rem;
                    margin-right: .16rem;
                }
            }
        }

    }

    .add-new-address {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        height: .64rem;
        overflow: hidden;
        background-color: #FFF;
        .add-button {
            margin-top: .1rem;
            margin-left: .16rem;
            margin-right: .16rem;
            height: .44rem;
            border-radius: .22rem;
            background: #73D13D;
            color: #FFF;
            font-size: .16rem;
            line-height: .44rem;
            text-align: center;
        }
    }

    .bottom {
        height: .72rem;
    }

}